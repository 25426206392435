import { Component, OnInit, Input } from "@angular/core";
import { Location } from "@angular/common";
import { Router } from "@angular/router";
import * as localForage from "localforage";

@Component({
  selector: "history-back",
  templateUrl: "./history-back.component.html",
  styleUrls: ["./history-back.component.css"],
})
export class HistoryBackComponent implements OnInit {
  @Input() disableHistoryBack: boolean;
  @Input() url: string;

  constructor(private locationService: Location, private router: Router) {}

  ngOnInit() {}

  navigate() {
    if (!this.disableHistoryBack) {
      this.locationService.back();
    } else if (this.url) {
      if (this.url === "login") {
        localStorage.setItem("logout-event", `logout${Math.random()}`);
        localForage.clear();
      }
      this.router.navigate([this.url]);
    }
  }
}
