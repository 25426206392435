import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AgCharts } from "ag-charts-angular";
import { SalesTrendChartComponent } from "./sales-trend-chart/sales-trend-chart.component";
import { HistoryBackComponent } from "./history-back/history-back.component";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatRippleModule } from "@angular/material/core";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { NotificationsComponent } from "./notifications/notifications.component";
import { SettingsComponent } from "./settings/settings.component";
import { RouterModule } from "@angular/router";
import { CheckboxSliderComponent } from "./checkbox-slider/checkbox-slider.component";
import { SearchComponent } from "./search/search.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { ButtonsGroupFilterComponent } from "./buttons-group-filter/buttons-group-filter.component";
import { PlTrendChartComponent } from "./pl-trend-chart/pl-trend-chart.component";

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatRippleModule,
    RouterModule,
    MatButtonModule,
    MatCardModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    FormsModule,
    AgCharts,
  ],
  declarations: [
    HistoryBackComponent,
    NotificationsComponent,
    SettingsComponent,
    CheckboxSliderComponent,
    SearchComponent,
    ButtonsGroupFilterComponent,
    SalesTrendChartComponent,
    PlTrendChartComponent,
  ],
  exports: [
    HistoryBackComponent,
    NotificationsComponent,
    SettingsComponent,
    CheckboxSliderComponent,
    SearchComponent,
    ButtonsGroupFilterComponent,
    SalesTrendChartComponent,
    PlTrendChartComponent,
  ],
})
export class ComponentsModule {}
