import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { QuicklinkStrategy, QuicklinkModule } from "ngx-quicklink";

const routes: Routes = [
  {
    path: "login",
    loadChildren: () =>
      import("./login-page/login-page.module").then((m) => m.LoginPageModule),
  },
  {
    path: "request-password",
    loadChildren: () =>
      import("./request-password/request-password.module").then(
        (m) => m.RequestPasswordModule
      ),
  },
  {
    path: "choose-account",
    loadChildren: () =>
      import("./choose-account/choose-account.module").then(
        (m) => m.ChooseAccountModule
      ),
  },
  {
    path: "home",
    loadChildren: () => import("./home/home.module").then((m) => m.HomeModule),
  },
  {
    path: "notifications",
    loadChildren: () =>
      import("./notifications/notifications.module").then(
        (m) => m.NotificationsModule
      ),
  },
  {
    path: "settings",
    loadChildren: () =>
      import("./settings/settings.module").then((m) => m.SettingsModule),
  },
  {
    path: "reports/accounts",
    loadChildren: () =>
      import("./reports/account-list/account-list.module").then(
        (m) => m.AccountListModule
      ),
  },
  {
    path: "reports/journal",
    loadChildren: () =>
      import("./reports/journal/journal.module").then((m) => m.JournalModule),
  },
  {
    path: "reports/income-statement",
    loadChildren: () =>
      import("./reports/income-statement/income-statement.module").then(
        (m) => m.IncomeStatementModule
      ),
  },
  {
    path: "reports/trial-balance",
    loadChildren: () =>
      import("./reports/trial-balance/trial-balance.module").then(
        (m) => m.TrialBalanceModule
      ),
  },
];

@NgModule({
  imports: [
    QuicklinkModule,
    RouterModule.forRoot(routes, {
      preloadingStrategy: QuicklinkStrategy,
      initialNavigation: "enabledBlocking",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
