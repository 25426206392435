import { Component, OnInit } from "@angular/core";
import * as localForage from "localforage";
import { sub } from "date-fns";
import { HomeService } from "src/app/home/home.service";

@Component({
  selector: "pl-trend-chart",
  templateUrl: "./pl-trend-chart.component.html",
})
export class PlTrendChartComponent implements OnInit {
  slideInStore: any;
  chartOptions: any;
  responseData: any;
  grossSalesDataForGraphThisYear: any[];
  grossSalesDataForGraphLastYear: any[];
  grossSalesYTD: { xytd: number; yytd: number; xkey?: string }[];
  expensesGraph: { x: number; y: number }[];
  netIncomeGraph: { x: number; y: number }[];
  grossSalesLastYear: { x: number; y: number }[];
  constructor(private homeService: HomeService) {
    this.grossSalesYTD = this.initializeArrayXYTD(12);
    this.expensesGraph = this.initializeArray(12);
    this.netIncomeGraph = this.initializeArray(12);
    this.grossSalesLastYear = this.initializeArray(12);
    this.chartOptions = [];
  }

  private initializeArray(length: number) {
    return Array.from({ length }, (_, index) => ({ x: index + 1, y: 0 }));
  }

  private initializeArrayXYTD(length: number) {
    return Array.from({ length }, (_, index) => ({ xytd: index + 1, yytd: 0 }));
  }

  async ngOnInit(): Promise<void> {
    const monthAbbreviations = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    this.slideInStore = await localForage.getItem("slideInStore");
    const prevYear = sub(new Date(), { years: 1 });
    const prevYearEnd = new Date(prevYear.getFullYear(), 11, 31);
    const firstDayOfTheYear = new Date(new Date().getFullYear(), 0, 1);
    const dateFormat = "yyyy-MM-dd";

    const thisYearData = {
      grossSalesData: [[253422.83, 2024, 1]],
      cogsData: [[66346.63, 2024, 1]],
      laborData: [[73122.03, 2024, 1]],
      operatingExpensesData: [
        [23532.34, 2024, 1],
        [472.28, 2024, 2],
      ],
      otherIncomeData: [],
      otherExpensesData: [],
      compsData: [[-4801.5, 2024, 1]],
    };

    const lastYearData = {
      grossSalesData: [
        [260666.78, 2023, 1],
        [263349.49, 2023, 2],
        [287289.44, 2023, 3],
        [263748.12, 2023, 4],
        [283456.8, 2023, 5],
        [274651.45, 2023, 6],
        [291601.28, 2023, 7],
        [274696.11, 2023, 8],
        [258089.54, 2023, 9],
        [256503.55, 2023, 10],
        [244394.05, 2023, 11],
        [303988.87, 2023, 12],
      ],
      cogsData: [
        [71021.19, 2023, 1],
        [63393.06, 2023, 2],
        [66568.44, 2023, 3],
        [64121.72, 2023, 4],
        [81905.41, 2023, 5],
        [66477.49, 2023, 6],
        [80800.84, 2023, 7],
        [80944.1, 2023, 8],
        [66065.85, 2023, 9],
        [70165.35, 2023, 10],
        [63869.22, 2023, 11],
        [74044.39, 2023, 12],
      ],
      laborData: [
        [86860.81, 2023, 1],
        [77971.38, 2023, 2],
        [88123.0, 2023, 3],
        [80394.98, 2023, 4],
        [84776.91, 2023, 5],
        [82907.06, 2023, 6],
        [87073.33, 2023, 7],
        [84561.15, 2023, 8],
        [78988.68, 2023, 9],
        [83187.62, 2023, 10],
        [79665.12, 2023, 11],
        [85197.27, 2023, 12],
      ],
      operatingExpensesData: [
        [67138.16, 2023, 1],
        [55922.01, 2023, 2],
        [75148.09, 2023, 3],
        [91948.57, 2023, 4],
        [73607.26, 2023, 5],
        [77899.44, 2023, 6],
        [61711.78, 2023, 7],
        [67469.23, 2023, 8],
        [63493.74, 2023, 9],
        [58259.57, 2023, 10],
        [60448.27, 2023, 11],
        [76062.64, 2023, 12],
      ],
      otherIncomeData: [],
      otherExpensesData: [],
      compsData: [
        [-4628.03, 2023, 1],
        [-3985.18, 2023, 2],
        [-4956.74, 2023, 3],
        [-4736.09, 2023, 4],
        [-5521.23, 2023, 5],
        [-6036.36, 2023, 6],
        [-5408.98, 2023, 7],
        [-5915.27, 2023, 8],
        [-6015.43, 2023, 9],
        [-5877.14, 2023, 10],
        [-4904.76, 2023, 11],
        [-4942.94, 2023, 12],
      ],
    };

    const storeId = this.slideInStore.currentStore.id;

    this.responseData = thisYearData;
    this.grossSalesDataForGraphThisYear = thisYearData.grossSalesData;
    this.grossSalesDataForGraphLastYear = lastYearData.grossSalesData;

    for(let index = 0; index < 11; index += 1) {
      if(this.grossSalesDataForGraphThisYear[index]) {
        this.grossSalesYTD[
          this.grossSalesDataForGraphThisYear[index][2] - 1
        ].yytd = this.grossSalesDataForGraphThisYear[index][0];

        if(
          this.responseData.cogsData.length === 0 ||
          !this.responseData.cogsData[index]
        ) {
          this.responseData.cogsData[index] = [0];
        }

        if(
          this.responseData.operatingExpensesData.length === 0 ||
          !this.responseData.operatingExpensesData[index]
        ) {
          this.responseData.operatingExpensesData[index] = [0];
        }

        if(
          this.responseData.laborData.length === 0 ||
          !this.responseData.laborData[index]
        ) {
          this.responseData.laborData[index] = [0];
        }

        if(
          this.responseData.compsData.length === 0 ||
          !this.responseData.compsData[index]
        ) {
          this.responseData.compsData[index] = [0];
        }

        this.expensesGraph[index].y =
          (this.responseData.cogsData[index][0] +
            this.responseData.operatingExpensesData[index][0] +
            this.responseData.laborData[index][0] -
            this.responseData.compsData[index][0]) *
          -1;
        this.netIncomeGraph[index].y =
          this.grossSalesYTD[index].yytd + this.expensesGraph[index].y;
      }

      if(this.grossSalesDataForGraphLastYear[index]) {
        this.grossSalesLastYear[
          this.grossSalesDataForGraphLastYear[index][2] - 1
        ].y = this.grossSalesDataForGraphLastYear[index][0];
      }
    }

    this.chartOptions = {
      series: [
        {
          type: "bar",
          xKey: "xytd",
          yKey: "yytd",
          yName: "Gross Sales",
          data: this.grossSalesYTD,
          tooltip: {
            renderer({ datum, xKey, yKey }) {
              return {
                content: datum[yKey],
                title: monthAbbreviations[datum[xKey] - 1],
              };
            },
          },
        },
        {
          type: "bar",
          xKey: "x",
          yKey: "y",
          yName: "Expenses",
          data: this.expensesGraph,
          tooltip: {
            renderer({ datum, xKey, yKey }) {
              return {
                content: datum[yKey],
                title: monthAbbreviations[datum[xKey] - 1],
              };
            },
          },
        },
        {
          type: "line",
          stroke: "#000",
          marker: {
            fill: "#000",
          },
          xKey: "x",
          yKey: "y",
          yName: "Net Income",
          data: this.netIncomeGraph,
          tooltip: {
            renderer({ datum, xKey, yKey }) {
              return {
                content: datum[yKey],
                title: monthAbbreviations[datum[xKey] - 1],
              };
            },
          },
        },
      ],
    };
  }
}
