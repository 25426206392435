import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import * as localForage from "localforage";
import { IUser } from "../interfaces/IUser";

interface ILogin {
  login: string;
}

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(private http: HttpClient) {}

  public login(userObj: IUser): Observable<IUser> {
    return this.http.post<IUser>(`${environment.apiUrl}/auth/token`, userObj);
  }

  public resetPassword(loginObj: ILogin): Observable<ILogin> {
    return this.http.post<ILogin>(
      `${environment.apiUrl}/resetpassword/request`,
      loginObj
    );
  }

  public async isLoggedIn(): Promise<boolean> {
    return await localForage.getItem("userLoggedIn");
  }
}
