<div>
  <form
    (submit)="search()"
    [formGroup]="billsSearchForm"
    class="bill-search-container"
  >
    <input
      type="text"
      class="bill-search-input form-control"
      placeholder="Search for bills ..."
      aria-label="Number"
      [(ngModel)]="searchinput"
      [formControl]="billsSearch"
    />
    <button class="btn btn--payables" mat-button (click)="search()">
      Search
    </button>
  </form>
  <div class="bill-search-error" *ngIf="searchError">
    Please, enter 3 or more characters to search
  </div>
</div>
