import { Component, OnInit, EventEmitter, Output, Input } from "@angular/core";

@Component({
  selector: "checkbox-slider",
  templateUrl: "./checkbox-slider.component.html",
  styleUrls: ["./checkbox-slider.component.css"],
})
export class CheckboxSliderComponent implements OnInit {
  public checked = false;
  @Input() name: string;
  @Input() valuechecked: boolean;
  @Output() checkBoxToggle: EventEmitter<object> = new EventEmitter<object>();

  constructor() {}

  ngOnInit() {
    this.checked = this.valuechecked;
  }

  checkboxChange($event: Event) {
    const checkboxObj = {};
    checkboxObj[this.name] = ($event.target as HTMLInputElement).checked;
    this.checkBoxToggle.emit(checkboxObj);
  }
}
