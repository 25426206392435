import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { IHome } from "../interfaces/IHome";

@Injectable({
  providedIn: "root",
})
export class HomeService {
  constructor(private http: HttpClient) {}

  public getHomeData(storeId: number): Observable<IHome> {
    return this.http.get<IHome>(
      `${environment.apiUrl}mobile/mainmenu/store/${storeId}`
    );
  }

  public getStoreFolders(storeId: number): Observable<[]> {
    return this.http.get<[]>(
      `${environment.apiUrl}mobile/files/and/folders/store/${storeId}`
    );
  }

  public learnMore(storeId: number): Observable<any> {
    return this.http.get<any>(
      `${environment.apiUrl}mobile/payables/learnmore/store/${storeId}`
    );
  }

  public async uploadFile(
    folderId: number,
    file: any
  ): Promise<Observable<any>> {
    return this.http.post(
      `${environment.apiUrl}mobile/file/upload/folder/${folderId}`,
      file
    );
  }

  public async deleteFile(fileId: number): Promise<Observable<any>> {
    return this.http.delete(
      `${environment.apiUrl}mobile/delete/file/${fileId}`
    );
  }

  public downLoadFile(fileId: number): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}mobile/download/file/${fileId}`,
      { responseType: "blob" }
    );
  }

  public async setPPP(
    storeId: number,
    amount: Object
  ): Promise<Observable<any>> {
    return this.http.post(
      `${environment.apiUrl}/mobile/ppp/loan/set/store/${storeId}`,
      amount
    );
  }
}
