import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { IButton } from "../../interfaces/IButton";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "buttons-group-filter",
  templateUrl: "./buttons-group-filter.component.html",
  styleUrls: ["./buttons-group-filter.component.css"],
})
export class ButtonsGroupFilterComponent implements OnInit {
  @Output() filterSelectedCallback: EventEmitter<string[]> = new EventEmitter();
  public statusButtons: IButton[] = [
    {
      text: "Unpaid",
      class: "btn--unpaid",
      active: true,
    },
    {
      text: "Paid",
      class: "btn--paid",
      active: true,
    },
    {
      text: "Pending",
      class: "btn--pending",
      active: true,
    },
  ];
  private statuses: string[];
  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.statuses = this.route.snapshot.queryParamMap.get("filters")
      ? this.route.snapshot.queryParamMap.get("filters").split(",")
      : ["paid", "unpaid", "pending"];
    this.statusButtons.forEach((btn, index) => {
      if(this.statuses.includes(btn.text.toLowerCase())) btn.active = true;
      else btn.active = false;
    });
  }

  statusChanged(button: any) {
    this.statusButtons.forEach((btn) => {
      if(button.text === btn.text && btn.active) {
        btn.active = false;
      } else if(button.text === btn.text) {
        btn.active = true;
      }
    });
    this.statuses = this.statusButtons
      .map((btn) => {
        if(btn.active) {
          return btn.text.toLowerCase();
        }
      })
      .filter(Boolean);
    this.filterSelectedCallback.emit(this.statuses);
  }
}
