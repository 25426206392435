<div>
  <mat-icon
    *ngIf="url !== 'login'"
    class="history-back"
    matRipple
    (click)="navigate()"
    >arrow_back_ios</mat-icon
  >
  <div class="history-back-button" *ngIf="url === 'login'" (click)="navigate()">
    <svg
      viewBox="0 0 470 470"
      width="26"
      height="26"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <title>background</title>
        <rect
          fill="none"
          id="canvas_background"
          height="402"
          width="582"
          y="-1"
          x="-1"
        />
      </g>
      <g>
        <g id="svg_1">
          <g id="svg_2">
            <path
              id="svg_3"
              d="m227.619,444.2l-122.9,0c-33.4,0 -60.5,-27.2 -60.5,-60.5l0,-296.2c0,-33.4 27.2,-60.5 60.5,-60.5l124.9,0c7.5,0 13.5,-6 13.5,-13.5s-6,-13.5 -13.5,-13.5l-124.9,0c-48.3,0 -87.5,39.3 -87.5,87.5l0,296.2c0,48.3 39.3,87.5 87.5,87.5l122.9,0c7.5,0 13.5,-6 13.5,-13.5s-6.1,-13.5 -13.5,-13.5z"
            />
            <path
              transform="rotate(179.97560119628906 287.25030517578125,235.71249389648435) "
              stroke="null"
              id="svg_4"
              d="m450.019,226.1l-85.8,-85.8c-5.3,-5.3 -13.8,-5.3 -19.1,0c-5.3,5.3 -5.3,13.8 0,19.1l62.8,62.8l-273.9,0c-7.5,0 -13.5,6 -13.5,13.5s6,13.5 13.5,13.5l273.9,0l-62.8,62.8c-5.3,5.3 -5.3,13.8 0,19.1c2.6,2.6 6.1,4 9.5,4s6.9,-1.3 9.5,-4l85.8,-85.8c5.4,-5.4 5.4,-14 0.1,-19.2z"
            />
          </g>
        </g>
        <g id="svg_5" />
        <g id="svg_6" />
        <g id="svg_7" />
        <g id="svg_8" />
        <g id="svg_9" />
        <g id="svg_10" />
        <g id="svg_11" />
        <g id="svg_12" />
        <g id="svg_13" />
        <g id="svg_14" />
        <g id="svg_15" />
        <g id="svg_16" />
        <g id="svg_17" />
        <g id="svg_18" />
        <g id="svg_19" />
      </g>
    </svg>
  </div>
</div>
