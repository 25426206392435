import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HistoryBackComponent } from "./history-back/history-back.component";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatRippleModule } from "@angular/material/core";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { NotificationsComponent } from "./notifications/notifications.component";
import { SettingsComponent } from "./settings/settings.component";
import { RouterModule } from "@angular/router";
import { CheckboxSliderComponent } from "./checkbox-slider/checkbox-slider.component";
import { SearchComponent } from "./search/search.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { ButtonsGroupFilterComponent } from "./buttons-group-filter/buttons-group-filter.component";
import { PppLoanComponent } from "./ppp-loan/ppp-loan.component";

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatRippleModule,
    RouterModule,
    MatButtonModule,
    MatCardModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  declarations: [
    HistoryBackComponent,
    NotificationsComponent,
    SettingsComponent,
    CheckboxSliderComponent,
    SearchComponent,
    ButtonsGroupFilterComponent,
    PppLoanComponent,
  ],
  exports: [
    HistoryBackComponent,
    NotificationsComponent,
    SettingsComponent,
    CheckboxSliderComponent,
    SearchComponent,
    ButtonsGroupFilterComponent,
    PppLoanComponent,
  ],
})
export class ComponentsModule {}
