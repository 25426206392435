import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  constructor(private http: HttpClient) {}

  public async markNotificationUnread(id: number): Promise<Observable<any>> {
    return this.http.put(`${environment.apiUrl}notification/${id}/unread`, {});
  }

  public async markNotificationRead(id: number): Promise<Observable<any>> {
    return this.http.put(`${environment.apiUrl}notification/${id}/read`, {});
  }

  public getNotificationTransaction(transactionId: number): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}mobile/transaction/${transactionId}/get/data/for/classify`
    );
  }

  public async postNotificationTransactionTransfer(
    transactionId: number
  ): Promise<Observable<any>> {
    return this.http.post(
      `${environment.apiUrl}mobile/transaction/${transactionId}/classify/transfer`,
      {}
    );
  }

  public async postNotificationTransactionOther(
    transactionId: number,
    description: string
  ): Promise<Observable<any>> {
    return this.http.post(
      `${environment.apiUrl}mobile/transaction/${transactionId}/classify/other`,
      {
        description,
      }
    );
  }
  public async postNotificationTransaction(
    transactionId: number,
    classificationId: number
  ): Promise<Observable<any>> {
    return this.http.post(
      `${environment.apiUrl}mobile/transaction/${transactionId}/classify`,
      {
        classificationId,
      }
    );
  }

  public getNewNotifications(
    userId: number,
    notificationId: number
  ): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}mobile/notifications/after/${notificationId}/user/${userId}`
    );
  }

  public async getNotifications(userId: number): Promise<Observable<any>> {
    return this.http.get(
      `${environment.apiUrl}mobile/notifications/initial/user/${userId}`
    );
  }
}
