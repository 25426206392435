import { Component, OnInit, isDevMode } from "@angular/core";
import { AuthService } from "./services/auth.service";
import { Router } from "@angular/router";
import packageJson from "package.json";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  title = "user-tabulate";
  public version: string;
  public isDevMode: boolean;
  constructor(private authService: AuthService, private router: Router) {}

  async ngOnInit(): Promise<void> {
    const isUserLoggedIn: boolean = await this.authService.isLoggedIn();
    this.version = packageJson.version;
    this.isDevMode = isDevMode();
    if (!isUserLoggedIn) {
      await this.router.navigate(["login"]);
    } else if (isUserLoggedIn && this.router.url === "/") {
      await this.router.navigate(["home"]);
    }
    window.addEventListener("storage", (event): void => {
      if (event.key === "logout-event") {
        this.router.navigate(["login"]);
      }
    });
  }
}
