<div class="payables-buttons-container">
  <div *ngFor="let button of statusButtons">
    <button
      [className]="
        button.active
          ? 'btn btn--payables mat-button mat-button-base ' + button.class
          : 'btn btn--payables mat-button mat-button-base btn--disabled ' +
            button.class
      "
      (click)="statusChanged(button)"
    >
      {{ button.text }}
    </button>
  </div>
</div>
