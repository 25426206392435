import { Component, OnInit } from "@angular/core";
import { HomeService } from "../../home/home.service";
import * as localForage from "localforage";
import { ISignInStore } from "../../interfaces/ISignInStore";

@Component({
  selector: "ppp-loan",
  templateUrl: "./ppp-loan.component.html",
  styleUrls: ["./ppp-loan.component.css"],
})
export class PppLoanComponent implements OnInit {
  constructor(private homeService: HomeService) {}
  public pppLoanData: any = {
    loanAmount: -1,
  };
  public loanGoalUser = null;
  public slideInStore: ISignInStore;
  public gotIt = false;
  public showPPPTile = true;
  public loading = false;
  async ngOnInit() {
    this.slideInStore = await localForage.getItem("slideInStore");
    await this.homeService
      .getHomeData(this.slideInStore.id)
      .subscribe((response) => {
        this.pppLoanData = response;
      });
  }

  async setPPP() {
    if (!this.loading) {
      this.loading = true;
      (
        await this.homeService.setPPP(this.slideInStore.id, {
          loanAmount: this.loanGoalUser,
        })
      ).subscribe((response) => {
        this.loading = false;
        this.gotIt = true;
      });
    }
  }

  async skipPPP() {
    this.loading = true;
    (
      await this.homeService.setPPP(this.slideInStore.id, {
        loanAmount: -1,
      })
    ).subscribe((response) => {
      this.loading = false;
      this.showPPPTile = false;
    });
  }
}
