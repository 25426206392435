import { Component, OnInit } from "@angular/core";
import * as localForage from "localforage";
import { HomeService } from "../../home/home.service";

@Component({
  selector: "sales-trend-chart",
  templateUrl: "./sales-trend-chart.component.html",
})
export class SalesTrendChartComponent implements OnInit {
  responseData: any;
  grossSalesDataForGraphThisYear: any[];
  grossSalesDataForGraphLastYear: any[];
  slideInStore: any;
  grossSalesYTD: { xytd: number; yytd: number; xkey?: string }[];
  expensesGraph: { x: number; y: number }[];
  netIncomeGraph: { x: number; y: number }[];
  grossSalesLastYear: { x: number; y: number }[];
  chartOptions: any;

  constructor(private homeService: HomeService) {
    this.grossSalesYTD = this.initializeArrayXYTD(12);
    this.expensesGraph = this.initializeArray(12);
    this.netIncomeGraph = this.initializeArray(12);
    this.grossSalesLastYear = this.initializeArray(12);
    this.chartOptions = [];
  }

  private initializeArray(length: number) {
    return Array.from({ length }, (_, index) => ({ x: index + 1, y: 0 }));
  }

  private initializeArrayXYTD(length: number) {
    return Array.from({ length }, (_, index) => ({ xytd: index + 1, yytd: 0 }));
  }

  private salesDataForChart(): any[] {
    return this.grossSalesLastYear.map((item, i) => ({
      x: item.x,
      y: item.y,
      xytd: this.grossSalesYTD[i].xytd,
      yytd: this.grossSalesYTD[i].yytd,
    }));
  }

  async ngOnInit(): Promise<void> {
    const monthAbbreviations = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    this.slideInStore = await localForage.getItem("slideInStore");

    const storeId = this.slideInStore.currentStore.id;

    const [thisYearData, lastYearData] = await this.homeService
      .getSalesDataForChart(storeId)
      .toPromise();

    this.responseData = thisYearData.responseData;
    this.grossSalesDataForGraphThisYear =
      thisYearData.grossSalesDataForGraphThisYear;
    this.grossSalesDataForGraphLastYear =
      lastYearData.grossSalesDataForGraphLastYear;

    for(let index = 0; index < 11; index += 1) {
      if(this.grossSalesDataForGraphThisYear[index]) {
        this.grossSalesYTD[
          this.grossSalesDataForGraphThisYear[index][2] - 1
        ].yytd = this.grossSalesDataForGraphThisYear[index][0];

        if(
          this.responseData.cogsData.length === 0 ||
          !this.responseData.cogsData[index]
        ) {
          this.responseData.cogsData[index] = [0];
        }

        if(
          this.responseData.operatingExpensesData.length === 0 ||
          !this.responseData.operatingExpensesData[index]
        ) {
          this.responseData.operatingExpensesData[index] = [0];
        }

        if(
          this.responseData.laborData.length === 0 ||
          !this.responseData.laborData[index]
        ) {
          this.responseData.laborData[index] = [0];
        }

        if(
          this.responseData.compsData.length === 0 ||
          !this.responseData.compsData[index]
        ) {
          this.responseData.compsData[index] = [0];
        }

        this.expensesGraph[index].y =
          (this.responseData.cogsData[index][0] +
            this.responseData.operatingExpensesData[index][0] +
            this.responseData.laborData[index][0] -
            this.responseData.compsData[index][0]) *
          -1;
        this.netIncomeGraph[index].y =
          this.grossSalesYTD[index].yytd + this.expensesGraph[index].y;
      }

      if(this.grossSalesDataForGraphLastYear[index]) {
        this.grossSalesLastYear[
          this.grossSalesDataForGraphLastYear[index][2] - 1
        ].y = this.grossSalesDataForGraphLastYear[index][0];
      }
    }

    this.chartOptions = {
      series: [
        {
          type: "bar",
          xKey: "xytd",
          yKey: "yytd",
          xName: "xkey",
          yName: "YTD Gross Sales",
          stackGroup: "ThisYear",
          tooltip: {
            renderer({ datum, xKey, yKey }) {
              return {
                content: datum[yKey],
                title: monthAbbreviations[datum[xKey] - 1],
              };
            },
          },
        },
        {
          type: "bar",
          xKey: "x",
          yKey: "y",
          xName: "Monthh",
          yName: "Previous Year Gross Sales",
          stackGroup: "LastYear",
          tooltip: {
            renderer({ datum, xKey, yKey }) {
              return {
                content: datum[yKey],
                title: monthAbbreviations[datum[xKey] - 1],
              };
            },
          },
        },
      ],
      axes: [
        {
          type: "category",
          position: "bottom",
          title: {
            text: "Desktop Operating Systems",
            enabled: false,
          },
          label: {
            formatter: (params) => {
              return monthAbbreviations[params.value - 1];
            },
          },
        },
        {
          type: "number",
          position: "left",
          title: {
            text: "Market Share (%)",
            enabled: false,
          },
        },
      ],
      data: this.salesDataForChart(),
    };
  }
}
