import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { forkJoin } from "rxjs";
import { map } from "rxjs/operators";
import { format, sub } from "date-fns";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class HomeService {
  constructor(private http: HttpClient) {}

  getSalesDataForChart(storeId: number) {
    const prevYearFirstDay = new Date(new Date().getFullYear() - 1, 0, 1);
    const prevYearEnd = new Date(new Date().getFullYear() - 1, 11, 31);
    const firstDayOfTheYear = new Date(new Date().getFullYear(), 0, 1);
    const dateFormat = "yyyy-MM-dd";

    const thisYearObservable = this.http
      .get(
        `${
          environment.apiUrl
        }/dashboard/pl/trend/store/${storeId}/start/${format(
          firstDayOfTheYear,
          dateFormat
        )}/end/${format(sub(new Date(), { days: 1 }), dateFormat)}`
      )
      .pipe(
        map((resp: any) => {
          return {
            responseData: resp,
            grossSalesDataForGraphThisYear: [
              [201240.35, 2023, 1],
              [184457.75, 2023, 2],
              [204277.55, 2023, 3],
              [199451.96, 2023, 4],
              [206684.0, 2023, 5],
              [199797.01, 2023, 6],
              [217242.19, 2023, 7],
              [134069.05, 2023, 8],
            ],
          };
        })
      );

    const lastYearObservable = this.http
      .get(
        `${
          environment.apiUrl
        }/dashboard/pl/trend/store/${storeId}/start/${format(
          prevYearFirstDay,
          dateFormat
        )}/end/${format(prevYearEnd, dateFormat)}`
      )
      .pipe(
        map((resp: any) => {
          return {
            grossSalesDataForGraphLastYear: resp.grossSalesData,
          };
        })
      );

    return forkJoin([thisYearObservable, lastYearObservable]);
  }
}
