import { Component, OnInit } from "@angular/core";
import * as localForage from "localforage";
import { IUser } from "../../interfaces/IUser";
import { NotificationsService } from "../../notifications/notifications.service";
import { maxBy } from "lodash";
import { INotification } from "../../interfaces/INotification";

@Component({
  selector: "notifications",
  templateUrl: "./notifications.component.html",
  styleUrls: ["./notifications.component.css"],
})
export class NotificationsComponent implements OnInit {
  private userInfo: IUser;
  private notifications: INotification[];
  private newestNotification: INotification;
  public showBubble = false;
  private newNotReadNotification: INotification[];
  constructor(private notificationsService: NotificationsService) {}

  async ngOnInit(): Promise<void> {
    this.userInfo = await localForage.getItem("userInfo");
    (
      await this.notificationsService.getNotifications(this.userInfo.person.id)
    ).subscribe((response) => {
      this.notifications = response;
      this.newestNotification = maxBy(
        this.notifications,
        (notification) => notification.id
      );
      this.newNotReadNotification = this.notifications.filter(
        (notification) => !notification.isRead
      );
      if (this.newNotReadNotification && this.newNotReadNotification[0]) {
        this.showBubble = true;
        localForage.setItem("userNotifications", this.newNotReadNotification);
      }
    });
  }
}
