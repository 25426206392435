import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { QuicklinkStrategy, QuicklinkModule } from "ngx-quicklink";

const routes: Routes = [
  {
    path: "login",
    loadChildren: () =>
      import("./login-page/login-page.module").then((m) => m.LoginPageModule),
  },
  {
    path: "choose-account",
    loadChildren: () =>
      import("./choose-account/choose-account.module").then(
        (m) => m.ChooseAccountModule
      ),
  },
  {
    path: "performance",
    loadChildren: () =>
      import("./performance/performance.module").then(
        (m) => m.PerformanceModule
      ),
  },
  {
    path: "home",
    loadChildren: () => import("./home/home.module").then((m) => m.HomeModule),
  },
  {
    path: "notifications",
    loadChildren: () =>
      import("./notifications/notifications.module").then(
        (m) => m.NotificationsModule
      ),
  },
  {
    path: "settings",
    loadChildren: () =>
      import("./settings/settings.module").then((m) => m.SettingsModule),
  },
  {
    path: "comparison",
    loadChildren: () =>
      import("./comparison/comparison.module").then((m) => m.ComparisonModule),
  },
  {
    path: "bank-balances",
    loadChildren: () =>
      import("./bankbalances/bankbalances.module").then(
        (m) => m.BankbalancesModule
      ),
  },
  {
    path: "payables",
    loadChildren: () =>
      import("./payables/payables.module").then((m) => m.PayablesModule),
  },
  {
    path: "search",
    loadChildren: () =>
      import("./searchresults/searchresults.module").then(
        (m) => m.SearchresultsModule
      ),
  },
  {
    path: "loan-forgiveness",
    loadChildren: () =>
      import("./loan-forgivness/loan-forgivness.module").then(
        (m) => m.LoanForgivnessModule
      ),
  },
];

@NgModule({
  imports: [
    QuicklinkModule,
    RouterModule.forRoot(routes, {
    preloadingStrategy: QuicklinkStrategy,
    initialNavigation: "enabledBlocking"
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
