import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";

@Component({
  selector: "search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.css"],
})
export class SearchComponent implements OnInit {
  public searchError = false;
  public billsSearch: UntypedFormControl = new UntypedFormControl();
  public billsSearchForm: UntypedFormControl = new UntypedFormControl();
  public searchinput = "";

  constructor() {}

  @Output() searchquery = new EventEmitter<string>();

  ngOnInit() {
    console.log("Component initialized");
  }

  search() {
    if (!this.searchinput || this.searchinput.length < 3) {
      this.searchError = true;
    } else {
      this.searchinput = this.searchinput.replace(/\//g, "-");
      this.searchquery.emit(this.searchinput);
      this.searchError = false;
    }
  }
}
