import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({
  name: "highlight",
})
export class HighlightPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(value: any, args: string[]): any {
    if(args) {
      if(args.length === 0) return value;
      let replacedValue: string[] = args.map((arg) => {
        const reg: RegExp = new RegExp(arg, "gi");
        return value.replace(reg, "<mark>$&</mark>");
      });
      if(replacedValue.length > 1) {
        replacedValue = replacedValue.filter((val) => val.includes("mark"));
      }
      if(replacedValue.length > 0) {
        return this.sanitizer.bypassSecurityTrustHtml(replacedValue[0]);
      }
      return value;
    }
    return value;
  }
}
